import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { Input, Directive } from "@angular/core";

@Directive()
export abstract class AbstractComponent {
  @Input()
  public requiredFieldMessage: string;

  protected hasRequiredValidator(abstractControl: AbstractControl): boolean {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }
    if (abstractControl["controls"]) {
      for (const controlName in abstractControl["controls"]) {
        if (abstractControl["controls"][controlName]) {
          if (
            this.hasRequiredValidator(abstractControl["controls"][controlName])
          ) {
            return true;
          }
        }
      }
    }
    return false;
  }

  protected getPatternValidator(
    abstractControl: AbstractControl
  ): ValidatorFn | null {
    if (abstractControl.validator) {
      const validator = abstractControl.validator({} as AbstractControl);
      if (validator && validator.pattern) {
        return abstractControl.validator;
      }
    }
    if (abstractControl["controls"]) {
      for (const controlName in abstractControl["controls"]) {
        if (abstractControl["controls"][controlName]) {
          return this.getPatternValidator(
            abstractControl["controls"][controlName]
          );
        }
      }
    }
    return null;
  }

  protected getRequiredMessage(): string {
    return this.getValidationMessage("message-validator-required");
  }

  protected collectInvalidMessage(validationResult: ValidationErrors): string {
    const result = [];
    if (validationResult.required) {
      if (this.requiredFieldMessage) {
        result.push(this.requiredFieldMessage);
      } else {
        result.push(this.getRequiredMessage());
      }
      delete validationResult.required;
    }
    if (validationResult.maxlength) {
      result.push(this.getValidationMessage("message-validator-length"));
      delete validationResult.maxlength;
    }
    if (validationResult.email) {
      result.push(this.getValidationMessage("message-validator-email"));
      delete validationResult.email;
    }
    if (Object.keys(validationResult).length > 0) {
      console.error("Unsupported error");
      console.error(validationResult);
      result.push(this.getValidationMessage("message-validator-invalid-value"));
    }
    return result.join(", ");
  }

  protected getValidationMessage(key: string): string {
    return document.getElementById(key)
      ? document.getElementById(key).innerText
      : key;
  }
}
