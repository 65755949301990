import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class ApplicationService {
  public moduleNameKey: Subject<string> = new Subject<string>();

  constructor() {}

  public getUserSymbol(
    userName: string,
    firstName: string,
    lastName: string
  ): string {
    if (firstName && lastName) {
      return (
        firstName.substring(0, 1).toUpperCase() +
        lastName.substring(0, 1).toUpperCase()
      );
    }
    if (userName) {
      return userName.substring(0, 1).toUpperCase();
    }
    return "";
  }
}
