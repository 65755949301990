<div class="container-fluid h-100">
  <div class="row" app-header></div>
  <div class="row h-100">
    <div class="col-12">
      <div class="content">
        <div style="margin: 2em 0">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>

