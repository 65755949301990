import {ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './general/login/login.component';
import {PageNotFoundComponent} from './general/page-not-found/page-not-found.component';
import {MainComponent} from './main/main.component';
import {AuthGuard} from './core/guard/auth.guard';
import {RoleGuard} from './core/guard/role.guard';
import {PageAccessDeniedComponent} from './general/page-access-denied/page-access-denied.component';
import {ProjectsListComponent} from "./projects/projects-list/projects-list.component";
import {LayoutTestComponent} from "./layout-test/layout-test.component";

const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '',
        component: MainComponent,
        canActivate: [AuthGuard, RoleGuard],
        children: [
            {
                path: 'projekt',
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
                canActivate: [AuthGuard, RoleGuard]
            },
            {
              path: 'projekty',
              loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
              canActivate: [AuthGuard, RoleGuard]
            },
        ]
    },
    {
        path: 'access-denied',
        component: PageAccessDeniedComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

export const AppRouting: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(appRoutes, {
    onSameUrlNavigation: "reload",
    relativeLinkResolution: "legacy",
  });
