<label>{{ this.label }}</label>
<div class="input-group mb-3">
  <input
    #inputElement
    [type]="type"
    [(ngModel)]="inputValue"
    (ngModelChange)="this.modelChange($event)"
    [class.is-invalid]="this.isInvalid"
    [size]="this.inputSize"
    [class]="'form-control ' + this.classInput"
    [id]="'form-control-' + this.namePrefix + this.formControlName"
    placeholder=""
    [disabled]="this.disabled"
  />
  <div class="input-group-append">
    <span class="input-group-text" id="basic-addon2">PLN</span>
  </div>
  <span *ngIf="this.isInvalid" class="error text-danger">{{
    this.invalidMessage
  }}</span>
  <label
    *ngIf="labelAfter"
    [for]="'form-control-' + this.namePrefix + this.formControlName"
    >{{ label }}</label
  >
</div>
