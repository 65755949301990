import { Injectable } from "@angular/core";

export abstract class AbstractStorage {
  private storage: Storage;

  constructor(storage: any) {
    this.storage = storage;
  }

  /**
   * Store the object in storage.
   */
  setItem(key: string, value: any): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  /**
   * Retrieves data from storage.
   */
  getItem(key: string): any {
    const value = this.storage.getItem(key);
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  }

  /**
   * Removes value from storage.
   */
  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  /**
   * Removes all data from storage.
   */
  clear(): void {
    this.storage.clear();
  }
}
