import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-layout-test',
  templateUrl: './layout-test.component.html',
  styleUrls: ['./layout-test.component.scss']
})
export class LayoutTestComponent implements OnInit {

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      data: new FormControl('2000-01-20', Validators.required),
    });
  }

  ngOnInit(): void {
  }

  public submit() {
    const data = this.form.get("data").value;
    alert("Wartosc daty: " + data);
  }

}
