import { AfterViewInit, Component, OnInit } from "@angular/core";
import {FormBuilder, FormGroup, FormGroupDirective, NgForm} from "@angular/forms";
import { AuthService } from "../../core/service/auth.service";
import { ErrorModel } from "../../core/model/error.model";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { NotificationService } from "../../core/service/notification.service";
import {ErrorStateMatcher} from "@angular/material/core";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements AfterViewInit {
  public forgetPasswordUrl: string = environment.forgetPasswordUrl;
  public loginForm: FormGroup;
  public hasLoginError: boolean;
  public loginErrorMessage: string;
  public typePassword: string = "password";
  public matcher;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private notificationService: NotificationService
  ) {
    this.matcher = new InputErrorStateMatcher(this.hasLoginError);
    this.loginForm = this.fb.group({
      email: [""],
      password: [""],
    });
  }

  login() {
    if (!this.loginForm.valid) {
      return;
    }
    this.loginForm.get('password').markAsTouched();
    const userName = this.loginForm.get("email").value;
    const password = this.loginForm.get("password").value;
    this.authService.login(userName, password).subscribe(
      () => {
        this.notificationService.successMessage('Witaj w aplikacji Proval');
        this.router.navigateByUrl("/projekty");
      },
      (error: ErrorModel) => {
        this.hasLoginError = true;
        this.matcher = new InputErrorStateMatcher(this.hasLoginError);
        this.loginErrorMessage = error.message;
        console.error(error);
        this.notificationService.errorMessage('Nie udało się zalogować');
      }
    );
  }

  ngAfterViewInit(): void {}

  public changePasswordField() {
    this.typePassword = this.typePassword === "password" ? "text" : "password";
  }
}

class InputErrorStateMatcher implements ErrorStateMatcher {
  constructor(private errorstate: boolean) {}
  isErrorState(control: FormControl|null, form: FormGroupDirective|NgForm|null):boolean {
    return this.errorstate;
  }
}
