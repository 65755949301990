<label
  *ngIf="!labelAfter"
  [for]="'form-control-' + this.namePrefix + this.formControlName"
  >{{ label }}</label
>
<div class="input-group mb-3">
  <input
    #inputElement
    [type]="this.type"
    [(ngModel)]="inputValue"
    (ngModelChange)="this.modelChange($event)"
    [class.is-invalid]="this.isInvalid"
    [size]="this.inputSize"
    [class]="'form-control ' + this.classInput"
    [id]="'form-control-' + this.namePrefix + this.formControlName"
    placeholder=""
    [disabled]="this.disabled"
  />
  <div class="input-group-append">
    <button
      class="btn btn-input"
      (click)="this.changePasswordField()"
      type="button"
    >
      <i class="icon-eye"></i>
      <i class="icon-eye-slash d-none"></i>
    </button>
  </div>
  <span *ngIf="this.isInvalid" class="error text-danger">{{
    this.invalidMessage
  }}</span>
  <label
    *ngIf="labelAfter"
    [for]="'form-control-' + this.namePrefix + this.formControlName"
    >{{ label }}</label
  >
</div>
