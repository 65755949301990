import { AfterViewInit, Component, OnInit } from "@angular/core";
import { AuthService } from "./core/service/auth.service";
import { HttpService } from "./core/service/http.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[app-root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  title = "testapp";

  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.restoreSession();
  }
}
