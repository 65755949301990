export class JwtTokenModel {
  header: any = {};
  payload: {
    roles?: string[];
    username?: string;
    user_id?: number;
    first_name?: string;
    last_name?: string;
    image?: string;
  } = {};

  signature: string;
  raw: string;

  static decode(tokenString: string) {
    if (!tokenString) {
      throw new Error("token string cannot be null");
    }
    const decodedToken = new JwtTokenModel();
    const parts = tokenString.split(".");
    decodedToken.header = JSON.parse(atob(parts[0]));
    decodedToken.payload = JSON.parse(atob(parts[1]));
    decodedToken.payload.roles = decodedToken.payload.roles || [];
    decodedToken.signature = parts[2];
    decodedToken.raw = tokenString;
    return decodedToken;
  }
}
