<label *ngIf="this.label" for="validatedCustomFile">{{ this.label }}</label>
<div class="custom-file custom-file-box">
  <input
    #fileElement
    *ngIf="inputValue === null"
    type="file"
    class="custom-file-input"
    (change)="onFileSelect($event)"
    id="validatedCustomFile"
  />
  <label
    *ngIf="inputValue === null && !this.localImageSelected"
    class="custom-file-label"
    for="validatedCustomFile"
    i18n
    >Choose an image or drag it here</label
  >
  <img
    #currentImage
    src=""
    alt="image"
    class="has-image"
    id="currentImage"
    [class.d-none]="!this.localImageSelected"
  />
  <div
    *ngIf="this.hasCurrentImage"
    class="has-image"
    [style.backgroundImage]="'url(' + this.getFileUrl() + ')'"
  ></div>
  <button
    *ngIf="this.localImageSelected || this.inputValue"
    (click)="this.removeImage()"
    type="button"
    class="btn btn-primary mt-2 d-flex d-sm-inline-flex"
    i18n
  >
    Remove image
  </button>
</div>
