<mat-toolbar color="primary">
  <div style="width: 50%">
    <a href="/projekty"><h1 style="color: white;">Proval</h1></a>
  </div>
  <div style="width: 50%; text-align: right">
    <ng-container *ngIf="this.isLogged()">

      <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>perm_identity</mat-icon>
      </button>

      <span>{{getUserTitle()}}</span>

      <button mat-icon-button [matMenuTriggerFor]="userMenu" class="example-icon" aria-label="Example icon-button with menu icon">
        <mat-icon>menu</mat-icon>
      </button>

      <mat-menu #userMenu="matMenu">
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Wyloguj</span>
        </button>
      </mat-menu>
    </ng-container>
  </div>
</mat-toolbar>
