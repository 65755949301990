<label *ngIf="label" [for]="this.getSelectId()">{{ label }}</label>
<select
  #selectElement
  class="form-control"
  [class.is-invalid]="this.isInvalid"
  (change)="modelChange($event.target.value)"
  [id]="this.getSelectId()"
>
  <option
    *ngIf="allowNull"
    [value]=""
    [selected]="this.currentSelectedValue == null"
    i18n
  >
    Choose...
  </option>
  <option
    *ngFor="let option of options"
    [value]="option.value"
    [selected]="this.isSelected(option)"
  >
    {{ option.label }}
  </option>
</select>
<span *ngIf="this.isInvalid" class="error text-danger">{{
  this.invalidMessage
}}</span>
