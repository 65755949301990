<link rel="stylesheet" type="text/css" href="/assets/css/0.min.css" />
<link rel="stylesheet" type="text/css" href="/assets/css/styleIO.min.css" />
<header>
  <div class="text-center">
    <h1 style="color: white">Proval App</h1>
  </div>
</header>

<div class="container">
  <div class="text-center">
    <h2>System rezerwacji</h2>
  </div>
  <div class="row justify-content-center">
    <p>Brak uprawnień do modułu</p>
  </div>
  <div class="row justify-content-center">
    <p><a href="javascript:window.history.go(-1)">Wstecz</a></p>
  </div>
</div>
