<div class="color-selector" #colorElement>
  <label>{{ this.label }}</label>
  <div>
    <ng-container *ngFor="let color of colors">
      <div
        class="
          custom-control
          color-item
          custom-checkbox custom-control-inline
          mb-16
          mr-16
        "
      >
        <input
          (click)="this.selectValue(color)"
          type="checkbox"
          class="custom-control-input"
          [checked]="this.isChecked(color)"
          [id]="
            componentPrefix + 'customControlColor-' + this.getColorId(color)
          "
        />
        <label
          class="custom-control-label color-item"
          [style.backgroundColor]="color"
          [for]="
            componentPrefix + 'customControlColor-' + this.getColorId(color)
          "
          >&nbsp;</label
        >
      </div>
    </ng-container>
  </div>
  <span *ngIf="this.isInvalid" class="error text-danger">{{
    this.invalidMessage
  }}</span>
</div>
