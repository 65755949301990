import { ModuleWithProviders, NgModule } from "@angular/core";
import { HttpService } from "./service/http.service";
import { SessionStorageService } from "./service/session-storage.service";
import { AuthService } from "./service/auth.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { JWTInterceptor } from "./http/jwt-interceptor";
import { AuthGuard } from "./guard/auth.guard";
import { RoleGuard } from "./guard/role.guard";
import { InputComponent } from "./form/input/input.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormService } from "./service/form.service";
import { CommonModule } from "@angular/common";
import { SelectComponent } from "./form/select/select.component";
import { FileSelectorComponent } from "./form/file-selector/file-selector.component";
import { ApplicationService } from "./service/application.service";
import { ColorSelectorComponent } from "./form/color-selector/color-selector.component";
import { RoleService } from "./service/role.service";
import { FileSelectorService } from "./form/file-selector/file-selector.service";
import { InputPriceComponent } from "./form/input-price/input-price.component";
import { InputPasswordComponent } from "./form/input-password/input-password.component";
import { InputDateComponent } from './form/input-date/input-date.component';
import {MaterialModule} from "../material/material.module";

@NgModule({
  declarations: [
    InputComponent,
    SelectComponent,
    FileSelectorComponent,
    ColorSelectorComponent,
    InputPriceComponent,
    InputPasswordComponent,
    InputDateComponent,
  ],
  providers: [FileSelectorService],
  exports: [
    InputComponent,
    SelectComponent,
    FileSelectorComponent,
    ColorSelectorComponent,
    InputPriceComponent,
    InputPasswordComponent,
    InputDateComponent,
  ],
  imports: [ReactiveFormsModule, FormsModule, CommonModule, MaterialModule],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: JWTInterceptor,
          multi: true,
        },
        ApplicationService,
        RoleService,
        AuthGuard,
        RoleGuard,
        HttpService,
        AuthService,
        SessionStorageService,
        FormService,
      ],
    };
  }
}
