import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { AbstractComponent } from "../../common/abstract-component";
import { FormService } from "../../service/form.service";
import { Subscription } from "rxjs";
import { StorageService } from "../../service/storage.service";
import { FileSelectorService } from "./file-selector.service";

@Component({
  selector: "app-file-selector, [app-file-selector]",
  templateUrl: "./file-selector.component.html",
  styleUrls: ["./file-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FileSelectorComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FileSelectorComponent,
      multi: true,
    },
  ],
})
export class FileSelectorComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @ViewChild("fileElement")
  public fileElement: ElementRef;

  @ViewChild("currentImage")
  public currentImage: ElementRef;

  @Input()
  public editMode: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public label: string;

  @Input()
  formControlName: string;

  public isInvalid: boolean;

  public invalidMessage: string;

  public inputValue: any;

  private onChange: any;

  private onTouched: any;

  public hasCurrentImage: boolean;

  private onValidatorChanged: () => void;

  private transferFocusSubscribe: Subscription;
  private requestBackendValidationFieldErrorSubscribe: Subscription;

  public localImageSelected: boolean;

  constructor(
    private formService: FormService,
    private storageService: StorageService,
    private fileSelectorService: FileSelectorService
  ) {
    super();
  }

  ngOnInit() {
    this.transferFocusSubscribe = this.formService.transferFocus.subscribe(
      (elem) => {
        if (elem === this.formControlName) {
          this.fileElement.nativeElement.focus();
          this.fileElement.nativeElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    );
    this.requestBackendValidationFieldErrorSubscribe =
      this.formService.requestBackendValidationFieldError.subscribe((elem) => {
        if (elem.field_name === this.formControlName) {
          this.isInvalid = true;
          this.invalidMessage = elem.message;
          if (elem.set_focus) {
            this.fileElement.nativeElement.focus();
            this.fileElement.nativeElement.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      });
  }

  public onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.inputValue = file;
      this.fileSelectorService.setImageToNull.next(false);

      const reader = new FileReader();
      reader.onload = (elem) => {
        this.currentImage.nativeElement.src = elem.target["result"];
      };
      reader.readAsDataURL(event.target.files[0]);

      this.currentImage.nativeElement.src = URL.createObjectURL(file);
      this.localImageSelected = true;

      if (this.onChange) {
        this.onChange(file);
      }
    }
  }

  public getFileUrl(): string {
    return this.storageService.getFullImageUrl(this.inputValue);
  }

  public removeImage() {
    this.inputValue = null;
    this.localImageSelected = false;
    this.hasCurrentImage = false;
    this.fileSelectorService.setImageToNull.next(true);
    if (this.onChange) {
      this.onChange(null);
    }
  }

  ngOnDestroy(): void {
    this.transferFocusSubscribe.unsubscribe();
    this.requestBackendValidationFieldErrorSubscribe.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.hasCurrentImage = true;
    }
    this.localImageSelected = false;
    this.inputValue = obj;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    let isRequiredError = false;
    const isTouched = control.touched;
    if (!this.inputValue && this.hasRequiredValidator(control)) {
      isRequiredError = true;
    }
    if (isRequiredError) {
      if (isTouched) {
        this.isInvalid = true;
        this.invalidMessage = this.getRequiredMessage();
      }
      return {
        required: true,
      };
    }
    this.isInvalid = false;
    this.invalidMessage = null;
    return null;
  }
}
