import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { AbstractComponent } from "../../common/abstract-component";
import { Subscription } from "rxjs";
import { FormService } from "../../service/form.service";

@Component({
  selector: "app-color-selector",
  templateUrl: "./color-selector.component.html",
  styleUrls: ["./color-selector.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ColorSelectorComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ColorSelectorComponent,
      multi: true,
    },
  ],
})
export class ColorSelectorComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public componentPrefix: string = "";

  @ViewChild("colorElement")
  public colorElement: ElementRef;

  public colors: string[] = [];

  constructor(private formService: FormService) {
    super();
    this.colors.push("#fc7400");
    this.colors.push("#fbbd07");
    this.colors.push("#00b5ad");
    this.colors.push("#1e791e");
    this.colors.push("#96089d");
    this.colors.push("#ba5dbe");
    this.colors.push("#ca84ce");
    this.colors.push("#66c343");
    this.colors.push("red");
    this.colors.push("#1f576e");
    this.colors.push("#f78080");
    this.colors.push("#ada094");
    this.colors.push("#63c2dd");
  }

  @Input()
  public disabled: boolean;

  @Input()
  public label: string;

  @Input()
  formControlName: string;

  public isInvalid: boolean;

  public invalidMessage: string;

  public inputValue: string;

  private onChange: any;

  private onTouched: any;

  private onValidatorChanged: () => void;

  private transferFocusSubscribe: Subscription;
  private requestBackendValidationFieldErrorSubscribe: Subscription;

  ngOnInit() {
    this.transferFocusSubscribe = this.formService.transferFocus.subscribe(
      (elem) => {
        if (elem === this.formControlName) {
          this.colorElement.nativeElement.focus();
          this.colorElement.nativeElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    );
    this.requestBackendValidationFieldErrorSubscribe =
      this.formService.requestBackendValidationFieldError.subscribe((elem) => {
        if (elem.field_name === this.formControlName) {
          this.isInvalid = true;
          this.invalidMessage = elem.message;
          if (elem.set_focus) {
            this.colorElement.nativeElement.focus();
            this.colorElement.nativeElement.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.transferFocusSubscribe.unsubscribe();
    this.requestBackendValidationFieldErrorSubscribe.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.inputValue = obj;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    let isRequiredError = false;
    const isTouched = control.touched;
    if (!this.inputValue && this.hasRequiredValidator(control)) {
      isRequiredError = true;
    }
    if (isRequiredError) {
      if (isTouched) {
        this.isInvalid = true;
        this.invalidMessage = this.getRequiredMessage();
      }
      return {
        required: true,
      };
    }
    this.isInvalid = false;
    this.invalidMessage = null;
    return null;
  }

  public getColorId(item: string): string {
    return item.replace("#", "");
  }

  public selectValue(color: string) {
    this.inputValue = color;
    this.onChange(color);
  }

  public isChecked(color: string): boolean {
    return this.inputValue ? this.inputValue === color : false;
  }
}
