<header>
  <div class="text-center">
    <h1>ProvalApp</h1>
  </div>
</header>

<div class="container">
  <div class="text-center">
    <h2>Błąd aplikacji</h2>
  </div>
  <div class="row justify-content-center">
    <p>Nie znaleziono strony</p>
  </div>
  <div class="row justify-content-center">
    <p><a href="javascript:window.history.go(-1)">Wstecz</a></p>
  </div>
</div>
