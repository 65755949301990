
import { DateTime } from "luxon";
import {InvalidArgumentException} from "../../shared/exception/invalid-argument.exception";

export class DateUtils {

  public static fromString(value: any): DateTime|null {
      if (!value) {
        return null;
      }
      if (typeof value !== 'string') {
        console.error("Provided value is not a string: " + value);
        return null;
      }
      const date = DateTime.fromISO(value);
      if (!date.isValid) {
        throw new InvalidArgumentException("Invalid date provided for parse from ISO: "  + value);
      }
      return date;
  }

  public static toJSDate(value: string): Date|null {
    if (!value) {
      return null;
    }
    if (typeof value !== 'string') {
      console.error("Provided value is not a string: " + value);
      return null;
    }
    const date = DateTime.fromISO(value);
    return date.toJSDate();
  }

  public static yearsInterval(firstDate: DateTime|null, compareWith: DateTime|null): number|null {
    if (!firstDate || !compareWith) {
      return null;
    }
    const interval = firstDate.diff(compareWith, ["years"]);
    if (interval) {
      return interval.years;
    }
    return null;
  }

  public static daysInterval(firstDate: DateTime|null, compareWith: DateTime|null): number|null {
    if (!firstDate || !compareWith) {
      return null;
    }
    const interval = firstDate.diff(compareWith, ["days"]);
    if (interval) {
      return Math.floor(interval.days);
    }
    return null;
  }

  static formatJSDate(value: string|null): string {
    if (!value) {
      return '';
    }
    return DateTime.fromISO(value).toFormat('dd-LL-y');
  }

  static formatForSubmitForm(value: any): string|null {
      // TODO Should check if Date or string
      if (value) {
        const date = DateTime.fromJSDate(value, {zone: 'Europe/Warsaw'});
        return date.setLocale('pl').toISODate();
      }
      return null;
  }

  public static fromStart(date: DateTime): number {
    const dateStart = DateUtils.fromString("1899-12-30T00:00:00");
    return Math.round(this.daysInterval(date, dateStart));
  }

  public static lastDayOfYear(date: DateTime): DateTime {
    return date.plus({years: 1}).minus({hours: 1});
  }

  /**
   * Return date as YYYY-MM-DD, eg. 2021-12-31
   * @param date
   */
  static toISODate(date: DateTime): string {
    return date.toISODate();
  }

  static toISODateTime(date: DateTime): string {
    return date.toISODate() + "T00:00:00";
  }
}
