import { Injectable } from "@angular/core";

import {of, ReplaySubject, throwError} from "rxjs";
import { Subject } from "rxjs";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { SessionStorageService } from "./session-storage.service";
import { JwtTokenModel } from "../model/jwt-token.model";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { ErrorModel } from "../model/error.model";
import { AuthResponseModel } from "../model/auth-response.model";
import { environment } from "../../../environments/environment";
import { Router } from "@angular/router";

@Injectable()
export class AuthService {
  static REFRESH_TOKEN_KEY = "refresh_token";
  static ACCESS_TOKEN_KEY = "access_token";
  static ANONYMOUS_REQUEST_HEADER = "skip-jwt-interceptor";

  loginSubject: ReplaySubject<JwtTokenModel> = new ReplaySubject<JwtTokenModel>(
    1
  );

  logoutSubject: Subject<boolean> = new Subject();

  /**
   * Access token of working user. This token is used in all http api requests.
   */
  accessToken: JwtTokenModel;

  /**
   * Store the URL so we can redirect after logging in.
   */
  public redirectUrl: string;

  public userChanged: Subject<boolean> = new Subject();

  /**
   * AuthService constructor.
   *
   * @param {HttpClient} http
   * @param {SessionStorageService} sessionStorage
   */
  constructor(
    private http: HttpClient,
    private router: Router,
    private sessionStorage: SessionStorageService
  ) {}

  restoreSession() {
    // autologin - eg. page refresh
    this.accessToken = this.sessionStorage.getItem(
      AuthService.ACCESS_TOKEN_KEY
    );
    if (this.accessToken) {
      this.loginSubject.next(this.accessToken);
    }
  }

  /**
   * Authenticates a user.
   *
   * @param {string} userName
   * @param {string} password
   */
  public login(userName: string, password: string): Observable<any> {
    const data = {
      email: userName,
      password: password,
    };

    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http
      .post(this.getApiUrl() + "/auth/login", data, {
        headers: headers,
      })
      .pipe(
        map((tokenData: any) => {
          this.authenticateByToken(
            tokenData.data.access_token,
            tokenData.data.refresh_token
          );
          this.userChanged.next(true);
          return tokenData;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   *
   * @param {string} token authentication token - JWT token string.
   * @param {string} refreshToken
   */
  public authenticateByToken(token: string, refreshToken: string) {
    const jwtToken = JwtTokenModel.decode(token);
    this.sessionStorage.setItem(AuthService.ACCESS_TOKEN_KEY, jwtToken);
    this.sessionStorage.setItem(AuthService.REFRESH_TOKEN_KEY, refreshToken);

    this.accessToken = jwtToken;
    this.loginSubject.next(this.accessToken);
  }

  public refreshToken(): Observable<AuthResponseModel> {
    const refreshToken = this.sessionStorage.getItem(
      AuthService.REFRESH_TOKEN_KEY
    );

    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");

    return <any>(
      this.http.post(
        this.getApiUrl() + "/auth/refresh",
        { refresh_token: refreshToken },
          { headers: headers }
      ).pipe(
        map((result: any) => {
          return result.data;
        }),
        catchError((error) => {
          console.error("Failed get token");
          return null;
        })
    ))
  }

  private handleError(httpError: HttpErrorResponse): any {

    let error: ErrorModel = {
      code: "" + httpError.status,
      message: "Unknown error",
      errors: [],
      raw: httpError,
      status: httpError.status
    }

    if (httpError.error && httpError.error.errors) {
      error.message = httpError.error.errors[0].message;
    }
    return throwError(error);
  }

  /**
   * Logout a user.
   */
  logout(): Observable<boolean> {
    const refreshToken = this.sessionStorage.getItem(
      AuthService.REFRESH_TOKEN_KEY
    );
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http.post(this.getApiUrl() + "/auth/logout", {refresh_token: refreshToken}, { headers }).pipe(
      map(
        () => {
          this.sessionStorage.clear();
          this.accessToken = null;
          this.logoutSubject.next();
          return true;
        },
        catchError((error) => {
          console.error("Failed logout");
          console.error(error);
          return of([]);
        })
      ));
  }

  /**
   * Indicates that user is already logged in.
   */
  isLoggedIn(): boolean {
    return this.accessToken != null;
  }

  getUserName(): string {
    if (this.accessToken) {
      return this.accessToken.payload.username;
    }
    return "";
  }

  getFirstName(): string {
    if (this.accessToken) {
      return this.accessToken.payload.first_name;
    }
    return null;
  }

  getLastName(): string {
    if (this.accessToken) {
      return this.accessToken.payload.last_name;
    }
    return null;
  }

  getUserId(): number {
    return this.accessToken.payload.user_id;
  }

  private getApiUrl(): string {
    return environment.apiHost + environment.apiSubPath;
  }

  public getImage() {
    if (this.accessToken) {
      return this.accessToken.payload.image;
    }
    return null;
  }

  public getRoles(): string[] {
    if (this.accessToken) {
      return this.accessToken.payload.roles;
    }
    return [];
  }
}
