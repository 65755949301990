import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { BackendValidationModel } from "../model/backend-validation.model";
import { BackendValidationModelOptions } from "../model/backend-validation-option.model";

@Injectable({
  providedIn: "root",
})
export class FormService {
  public transferFocus: Subject<string> = new Subject<string>();
  public requestBackendValidationFieldError: Subject<BackendValidationModelOptions> =
    new Subject<BackendValidationModelOptions>();

  constructor() {}
}
