import {Injectable, OnDestroy, OnInit} from "@angular/core";
import { HttpService } from "./http.service";
import { HttpClient } from "@angular/common/http";
import {Observable, of, Subject} from "rxjs";
import {catchError, map, takeUntil} from "rxjs/operators";
import {UserModel} from "../model/user.model";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class UserService  {

  private http: HttpClient;

  constructor(private httpService: HttpService,
              private authService: AuthService) {}

  public refreshUserInfo(): Observable<UserModel> {
    return this.httpService.get("/users/me").pipe(
      map(
        (result) => {
          return result.data;
        },
        catchError((error) => {
          console.error("Failed call delete request");
          console.error(error);
          return of([]);
        })
      )
    );
  }


}
