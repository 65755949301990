import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { AbstractComponent } from "../../common/abstract-component";
import { Subscription } from "rxjs";
import { FormService } from "../../service/form.service";

@Component({
  selector: "app-input-price, [app-input-price]",
  templateUrl: "./input-price.component.html",
  styleUrls: ["./input-price.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputPriceComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputPriceComponent,
      multi: true,
    },
  ],
})
export class InputPriceComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public label: string;

  @Input()
  public classInput: string = "";

  @Input()
  public inputSize: string = "20";

  @Input()
  public namePrefix: string = "";

  @ViewChild("inputElement")
  public inputElement: ElementRef;

  @Input()
  public disabled: boolean;

  @Input()
  formControlName: string;

  public isInvalid: boolean;

  public invalidMessage: string;

  public inputValue: string;

  private onChange: any;

  private onTouched: any;

  private onValidatorChanged: () => void;

  private transferFocusSubscribe: Subscription;
  private requestBackendValidationFieldErrorSubscribe: Subscription;

  @Input()
  public labelAfter: boolean = false;

  @Input()
  public type: string = "text";

  constructor(private formService: FormService) {
    super();
  }

  ngOnInit() {
    this.transferFocusSubscribe = this.formService.transferFocus.subscribe(
      (elem) => {
        if (elem === this.formControlName) {
          this.inputElement.nativeElement.focus();
          this.inputElement.nativeElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    );
    this.requestBackendValidationFieldErrorSubscribe =
      this.formService.requestBackendValidationFieldError.subscribe((elem) => {
        if (elem.field_name === this.formControlName) {
          this.isInvalid = true;
          this.invalidMessage = elem.message;
          if (elem.set_focus) {
            this.inputElement.nativeElement.focus();
            this.inputElement.nativeElement.scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.transferFocusSubscribe.unsubscribe();
    this.requestBackendValidationFieldErrorSubscribe.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.inputValue = obj;
  }

  public modelChange($event: any) {
    this.onChange($event);
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    let testValue = "";
    let touched = false;
    if (control && control.touched) {
      touched = true;
    }
    if (control && control.validator) {
      testValue = control.value;
    } else {
      testValue = this.inputValue == null ? "" : this.inputValue;
    }

    // @ts-ignore
    const validationResult = control.validator && control.validator(control);
    if (validationResult) {
      if (touched) {
        this.invalidMessage = this.collectInvalidMessage(validationResult);
        this.isInvalid = true;
      }
    } else {
      this.isInvalid = false;
      this.invalidMessage = null;
    }
    return validationResult;
  }
}
