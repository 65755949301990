import {NgModule} from "@angular/core";
import {LoginComponent} from "./login/login.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import {PageAccessDeniedComponent} from "./page-access-denied/page-access-denied.component";
import {HeaderComponent} from './header/header.component';
import {MaterialModule} from "../material/material.module";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  declarations: [
    LoginComponent,
    PageNotFoundComponent,
    PageAccessDeniedComponent,
    HeaderComponent,
  ],
  exports: [
    LoginComponent, PageNotFoundComponent, HeaderComponent,
  ],
})
export class GeneralModule {
}
