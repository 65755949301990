<div class="container-fluid h-100">
  <div class="row" app-header></div>
  <div class="row h-100">
    <div class="col-12">
      <div class="container">
          <div style="margin: 2em 0">
          <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="row justify-content-center">
                  <h1>Logowanie do aplikacji</h1>
                </div>

                <div class="row justify-content-center">
                  <mat-form-field [style.width.px]=327  appearance="fill">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" formControlName="email">
                  </mat-form-field>
                </div>

                <div class="row justify-content-center">
                    <mat-form-field [style.width.px]=327  appearance="fill">
                      <mat-label>Hasło</mat-label>
                      <input matInput type="password" formControlName="password" [errorStateMatcher]="matcher">
                      <mat-error>{{loginErrorMessage}}</mat-error>
                    </mat-form-field>
                </div>

                <div class="row justify-content-center">
                  <button style="margin-top: 2em" type="submit "mat-raised-button color="primary">Zaloguj się</button>
                </div>
          </form>
          </div>
        </div>
    </div>
  </div>
</div>

