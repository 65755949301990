import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {AbstractComponent} from "../../common/abstract-component";
import {Subscription} from "rxjs";
import {FormService} from "../../service/form.service";
import {DateUtils} from "../../common/date-utils";
import {TableRow} from "../../../shared/table/table-row";

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => InputDateComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: InputDateComponent,
      multi: true,
    },
  ],
})
export class InputDateComponent extends AbstractComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Output()
  public dateFromDatePickerChanged: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  public label: string;

  @Input()
  public classInput: string = "";

  @Input()
  public inputSize: string = "20";

  @Input()
  public namePrefix: string = "";

  @Input()
  public disabled: boolean;

  @Input()
  formControlName: string;

  public isInvalid: boolean;

  public invalidMessage: string;

  public inputValue: any;

  private onChange: any;

  private onTouched: any;

  private onValidatorChanged: () => void;

  private transferFocusSubscribe: Subscription;
  private requestBackendValidationFieldErrorSubscribe: Subscription;

  @Input()
  public labelAfter: boolean = false;

  @Input()
  public type: string = "text";

  constructor(private formService: FormService) {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.transferFocusSubscribe) {
      this.transferFocusSubscribe.unsubscribe();
    }
    if (this.requestBackendValidationFieldErrorSubscribe) {
      this.requestBackendValidationFieldErrorSubscribe.unsubscribe();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    this.inputValue = DateUtils.toJSDate(obj);
  }

  public modelChange($event: any) {
    this.onChange(DateUtils.formatForSubmitForm($event));
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return null;
    /*
    let testValue = "";
    let touched = false;
    if (control && control.touched) {
      touched = true;
    }
    if (control && control.validator) {
      testValue = control.value;
    } else {
      testValue = this.inputValue == null ? "" : this.inputValue;
    }

    // @ts-ignore
    const validationResult = control.validator && control.validator(control);
    if (validationResult) {
      if (touched) {
        this.invalidMessage = this.collectInvalidMessage(validationResult);
        this.isInvalid = true;
      }
    } else {
      this.isInvalid = false;
      this.invalidMessage = null;
    }
    return validationResult;*/
  }

  public inputDateChanged($event: Event) {
    alert("Mamy zmiane");
    this.onChange(DateUtils.formatForSubmitForm($event));
  }

  public emitInput($event: Event) {
    alert("Powinienem input wyemitowac");
  }

  public onDateChange($event: Event) {
    this.onChange(DateUtils.formatForSubmitForm($event));
    this.dateFromDatePickerChanged.emit(DateUtils.formatForSubmitForm($event));
  }
}

