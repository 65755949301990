import { Injectable } from "@angular/core";
import { ReplaySubject, throwError, of, Observable } from "rxjs";
import { Subject } from "rxjs";
import { catchError, filter, map, retry, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { ErrorModel } from "../model/error.model";
import { environment } from "../../../environments/environment";

@Injectable()
export class HttpService {
  private http2: HttpClient;

  constructor(
    private http: HttpClient,
    private backend: HttpBackend,
    private authService: AuthService,
    private router: Router
  ) {
    this.http2 = new HttpClient(backend);
  }

  /**
   * Sends a http request using the `get` method.
   *
   * @param {string} resource
   * @param {Object} data
   *
   * @returns {any}
   */
  public get(resource: string, data: object = {}): Observable<any> {
    const requestOptions = {
      params: this.getUrlSearchParams(data),
    };

    return this.http
      .get(this.getResourceUrl(resource), requestOptions)
      .pipe(
        filter(this.filterResult.bind(this)),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Sends a http request using the post method.
   *
   * @param {string} resource
   * @param {Object} data
   * @param {array<UploadFileModel>} files
   * @returns {any}
   */
  public post(resource: string, data: any = {}): Observable<any> {
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http
      .post(this.getResourceUrl(resource), data, {
        headers: headers,
      })
      .pipe(
        map((tokenData: any) => {
          return tokenData;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  /**
   * Sends a http request using the post method.
   *
   * @param {string} resource
   * @param {Object} data
   * @returns {any}
   */
  public postMultipartFormData(resource: string, data: any): Observable<any> {
    return this.http.post(this.getResourceUrl(resource), data).pipe(
      map((tokenData: any) => {
        return tokenData;
      }),
      catchError(this.handleError.bind(this))
    );
  }

  public put(resource: string, data: any = {}): Observable<any> {
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http
      .put(this.getResourceUrl(resource), data, {
        headers: headers,
      })
      .pipe(
        map((tokenData: any) => {
          return tokenData;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  public delete(resource: string, data: any = {}): Observable<any> {
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http
      .delete(this.getResourceUrl(resource), {
        headers: headers,
        params: data,
      })
      .pipe(
        map((tokenData: any) => {
          return tokenData;
        }),
        catchError(this.handleError.bind(this))
      );
  }

  private filterResult(resp) {
    return !resp || resp.status !== 401;
  }

  /**
   * Returns a resource url.
   *
   * @param {string} resource
   *
   * @returns {string}
   */
  public getResourceUrl(resource: string): string {
    let uri = resource;
    if (uri && uri[0] === "/") {
      uri = uri.slice(1);
    }
    return environment.apiHost + environment.apiSubPath + "/" + uri;
  }

  /**
   * Returns data as url parameters.
   *
   * @param {Object} data
   *
   * @returns {HttpParams}
   */
  private getUrlSearchParams(data: object = {}): HttpParams {
    let params = new HttpParams();
    Object.keys(data).forEach((key) => {
      params = params.set(key, data[key]);
    });
    return params;
  }

  /**
   * Returns a response error message.
   *
   * @param {any} httpError
   *
   * @returns {ErrorObservable}
   */
  private handleError(httpError: HttpErrorResponse): Observable<ErrorModel> {
    if (httpError.status === 401) {
      this.router.navigate(["/login"]);

      const err: ErrorModel = new ErrorModel();
      err.status = 401;
      err.code = "UNAUTHORIZED";
      err.message = "general.messages.session-expired";

      return of(err); // Nie rzucać błędu! W przeciwnym razie nie zadziała `filter` na metodach GET/POST itp
    }

    if (httpError.status === 403) {
      const err: ErrorModel = new ErrorModel();
      err.status = 403;
      err.code = "FORBRIDDEN";
      err.message = "Brak uprawnień do wykonania operacji";

      return throwError(err);
    }

    const error = httpError.error;
    let retVal: ErrorModel;
    if (error == null || error.error == null) {
      retVal = {
        status: httpError.status,
        message: httpError.statusText,
        code: "UNKNOWN_ERROR",
        raw: httpError,
      };
    } else {
      retVal = error.error;
    }
    return throwError(retVal);
  }

  public patch(resource: string, data: any) {
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("Accept", "application/json, text/plain, */*");
    return this.http
      .patch(this.getResourceUrl(resource), data, {
        headers: headers,
      })
      .pipe(
        map((tokenData: any) => {
          return tokenData;
        }),
        catchError(this.handleError.bind(this))
      );
  }
}
