import {
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from "@angular/forms";
import { SelectOption } from "./select-option";
import { Subscription } from "rxjs";
import { FormService } from "../../service/form.service";
import { AbstractComponent } from "../../common/abstract-component";

@Component({
  selector: " app-select, [app-select]",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SelectComponent),
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  public namePrefix: string = "";

  @Input()
  public allowNull: boolean = true;

  @Input()
  public options: SelectOption[] = [];

  @Input()
  public label: string;

  @ViewChild("selectElement")
  public selectElement: ElementRef;

  @Input()
  public disabled: boolean;

  @Input()
  formControlName: string;

  public isInvalid: boolean;

  @Input()
  public transferIntValue: boolean = true;

  public invalidMessage: string;

  public currentSelectedValue: SelectOption;

  private onChange: any;

  private onTouched: any;

  private onValidatorChanged: () => void;

  private transferFocusSubscribe: Subscription;
  private requestBackendValidationFieldErrorSubscribe: Subscription;

  constructor(private formService: FormService) {
    super();
  }

  ngOnInit() {
    this.transferFocusSubscribe = this.formService.transferFocus.subscribe(
      (elem) => {
        if (elem === this.formControlName) {
          this.selectElement.nativeElement.focus();
          this.selectElement.nativeElement.scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    );
    this.requestBackendValidationFieldErrorSubscribe =
      this.formService.requestBackendValidationFieldError.subscribe((elem) => {
        if (elem.field_name === this.formControlName) {
          this.isInvalid = true;
          this.invalidMessage = elem.message;
          if (elem.set_focus) {
            this.selectElement.nativeElement.scrollIntoView({
              behavior: "smooth",
            });
            this.selectElement.nativeElement.focus();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.transferFocusSubscribe.unsubscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: any): void {
    if (!obj) {
      this.currentSelectedValue = null;
      return;
    }
    if (obj.value) {
      this.currentSelectedValue = obj;
    } else {
      this.currentSelectedValue = {
        label: "writedvalue",
        value: obj,
      };
    }
  }

  public modelChange($event: any) {
    if ($event) {
      let value = $event;
      if (this.transferIntValue) {
        value = parseInt($event, 0);
      }
      if (!this.currentSelectedValue) {
        this.currentSelectedValue = { value: value, label: "settedvalue" };
      } else {
        this.currentSelectedValue.value = value;
      }
      this.onChange(value);
    } else {
      this.currentSelectedValue = null;
      this.onChange(null);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChanged = fn;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    let isRequiredError = false;
    const isTouched = control.touched;
    if (!this.currentSelectedValue && this.hasRequiredValidator(control)) {
      isRequiredError = true;
    }
    if (isRequiredError) {
      if (isTouched) {
        this.isInvalid = true;
        if (this.requiredFieldMessage) {
          this.invalidMessage = this.requiredFieldMessage;
        } else {
          this.invalidMessage = this.getRequiredMessage();
        }
      }
      return {
        required: true,
      };
    }
    this.isInvalid = false;
    this.invalidMessage = null;
    return null;
  }

  public compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.value === c2.value : c1 === c2;
  }

  public getSelectId(): string {
    return this.namePrefix + "select-" + this.formControlName;
  }

  public isSelected(option: SelectOption): boolean {
    if (!this.currentSelectedValue) {
      return false;
    }
    if (this.transferIntValue) {
      return (
        parseInt(this.currentSelectedValue.value, 0) ===
        parseInt(option.value, 0)
      );
    } else {
      return this.currentSelectedValue.value === option.value;
    }
  }
}
