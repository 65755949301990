import { Component, Injectable, TemplateRef } from "@angular/core";
import {MatSnackBar} from "@angular/material/snack-bar";


@Injectable({ providedIn: "root" })
export class NotificationService {

  private messageDuration = 1.5;

  constructor(private _snackBar: MatSnackBar) {}

  private openSnackBar(message: string, panelClass: string) {
    this._snackBar.open(message, null,  {
      duration: this.messageDuration * 1000,
      panelClass: panelClass
    });
  }

  public successMessage(message: string) {
    this.openSnackBar(message, 'message-ok');
  }
  public errorMessage(message: string) {
    this.openSnackBar(message, 'message-error');
  }

}
