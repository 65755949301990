import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { SessionStorageService } from "../service/session-storage.service";
import { JwtTokenModel } from "../model/jwt-token.model";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "../service/auth.service";
import { AuthResponseModel } from "../model/auth-response.model";
import {
  catchError,
  filter,
  finalize,
  flatMap,
  switchMap,
  take,
} from "rxjs/operators";

@Injectable()
export class JWTInterceptor implements HttpInterceptor {
  isRefreshingTokenProcessing: boolean = false;
  tokenSubject: BehaviorSubject<AuthResponseModel> =
    new BehaviorSubject<AuthResponseModel>(null);

  constructor(
    private sessionStorage: SessionStorageService,
    private injector: Injector
  ) {}

  private isLoginRequest(req: HttpRequest<any>): boolean {
    return req && req.url.includes("auth/login");
  }

  private isRefreshTokenRequest(req: HttpRequest<any>): boolean {
    return req && req.url.includes("auth/refresh");
  }

  private setAuthHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token: JwtTokenModel = this.sessionStorage.getItem("access_token");
    if ((!this.isLoginRequest(req)) && (!this.isRefreshTokenRequest(req)) && (!req.headers.get("Authorization") )&& token) {
      req = req.clone({
        setHeaders: {
          Authorization: "Bearer " + token.raw,
        },
      });
    }
    return req;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get("skip-jwt-interceptor")) {
      return next.handle(req);
    }
    const authReq = this.setAuthHeader(req);

    return next.handle(authReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 401:
              return this.handle401Error(req, next);
          }
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isRefreshingTokenProcessing) {
      this.isRefreshingTokenProcessing = true;

      // Reset here so that the following requests wait until the token
      // comes back from the refreshToken call.
      this.tokenSubject.next(null);
      const authService: AuthService = this.injector.get(AuthService);
      return authService.refreshToken().pipe(
        flatMap((newTokenPair: AuthResponseModel) => {
          authService.authenticateByToken(
            newTokenPair.access_token,
            newTokenPair.refresh_token
          );
          this.tokenSubject.next(newTokenPair);
          return next.handle(this.setAuthHeader(req));
        }),
        finalize(() => {
          this.isRefreshingTokenProcessing = false;
        }),
        catchError((msg: any) => {
          throwError("Refresh token get failed");
          return next.handle(this.setAuthHeader(req));
        })
      );
    } else {
      return this.tokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) => {
          return next.handle(this.setAuthHeader(req));
        })
      );
    }
  }
}
