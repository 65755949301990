import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { AuthService } from "../service/auth.service";
import { NotificationService } from "../service/notification.service";
import { RoleService } from "../service/role.service";

/**
 * Route guard which is checking if logged user have assigned particular role.
 */
@Injectable()
export class RoleGuard implements CanActivate {
  constructor(
    private roleService: RoleService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.data) {
      return true;
    }

    const roles = route.data.roles as Array<string>;
    if (!roles || !roles.length) {
      return true;
    }

    for (const role of roles) {
      if (this.roleService.hasRole(role)) {
        return true;
      }
    }

    this.router.navigateByUrl("/access-denied");
    return false;
  }
}
