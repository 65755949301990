import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { AppRouting } from "./app.routing";
import { AppComponent } from "./app.component";
import { GeneralModule } from "./general/general.module";
import { CoreModule } from "./core/core.module";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MainComponent } from "./main/main.component";
import localePl from "@angular/common/locales/pl";
import { registerLocaleData } from "@angular/common";
import { TRANSLATIONS } from "@angular/core";
import { LayoutTestComponent } from './layout-test/layout-test.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "./material/material.module";

declare const require; // Use the require method provided by webpack

registerLocaleData(localePl, "pl-PL");

@NgModule({
  declarations: [AppComponent, MainComponent, LayoutTestComponent],
  providers: [
    {
      provide: TRANSLATIONS,
      useFactory: (locale) => {
        locale = locale || "en"; // default to english if no locale provided
        return require(`raw-loader!../../locale/messages.${locale}.xlf`)
          .default;
      },
      deps: [LOCALE_ID],
    },
    {
      provide: LOCALE_ID,
      useValue: "pl",
    },
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    HttpClientJsonpModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule.forRoot(),
    AppRouting,
    GeneralModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
