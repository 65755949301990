import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class RoleService {
  constructor(private authService: AuthService) {}

  public hasRole(roleName: string): boolean {
    return this.authService.getRoles().indexOf(roleName) > -1;
  }
}
