<div class="app">
  <div class="container">
  Test layout

  <h1>Test H1</h1>
  <h2>Test H2</h2>


  <form [formGroup]="form" (ngSubmit)="submit()">
    <div claass="">
      <app-input-date formControlName="data"></app-input-date>
    </div>
    <div class="row">
      <button type="submit " mat-raised-button color="primary">Zapisz</button>
    </div>
  </form>

  </div>
</div>
