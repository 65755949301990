import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { AuthService } from "../service/auth.service";

@Injectable()
/**
 * Guard prevents access to protected resource for anonymous users.
 */
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    }
    this.authService.redirectUrl = state.url;
    this.router.navigate(["/login"]);
    return false;
  }
}
