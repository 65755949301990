import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from "../../core/service/auth.service";
import {Router} from "@angular/router";
import {UserService} from "../../core/service/user.service";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {UserModel} from "../../core/model/user.model";

@Component({
  selector: '[app-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

  private userTitle: string;

  constructor(private authService: AuthService,
              private userService: UserService,
              private router: Router) {

  }

  public getUserTitle(): string {
    return this.userTitle;
  }

  ngOnInit(): void {
    if (this.userTitle == null) {
      this.userService.refreshUserInfo().subscribe((result) => {
        this.setUserTitle(result);
      });
    }

    this.authService.userChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((request) => {
      this.userService.refreshUserInfo().subscribe((result) => {
        this.setUserTitle(result);
      });
    });
  }


  public logout() {
    this.authService.logout().subscribe((result) => {
      this.router.navigateByUrl("/login");
    });
  }

  public isLogged(): boolean {
    return this.authService.isLoggedIn();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private setUserTitle(result: UserModel) {
    if (result.first_name && result.last_name) {
      this.userTitle = result.first_name + " " + result.last_name + " (" + result.email + ")";
    } else {
      this.userTitle = result.email;
    }
  }
}
