<label
  *ngIf="!labelAfter"
  [for]="'form-control-' + this.namePrefix + this.formControlName"
  >{{ label }}</label
>
<input
  #inputElement
  [type]="type"
  [(ngModel)]="inputValue"
  (ngModelChange)="this.modelChange($event)"
  [class.is-invalid]="this.isInvalid"
  [size]="this.inputSize"
  [class]="'form-control ' + this.classInput"
  [id]="'form-control-' + this.namePrefix + this.formControlName"
  placeholder=""
  [disabled]="this.disabled"
/>
<span *ngIf="this.isInvalid" class="error text-danger">{{
  this.invalidMessage
}}</span>
<label
  *ngIf="labelAfter"
  [for]="'form-control-' + this.namePrefix + this.formControlName"
  >{{ label }}</label
>
