import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor(private httpService: HttpService) {}

  public getFullImageUrl(subpath: string): string {
    return environment.apiHost + subpath;
  }

}
