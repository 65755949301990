import { AfterViewInit, Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import {ActivatedRoute, Route, Router} from "@angular/router";


@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.scss"],
})
export class MainComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit() {
  }

}
